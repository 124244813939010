// import React, { useState, useEffect, useCallback, createRef } from 'react';
import React, { useState, useEffect } from 'react';

function Countdown(props) {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    console.log("start interval")
    let interval = setInterval(() => {
      let newSeconds = seconds + 1
      console.log("update interval")

      if((props.countStart - newSeconds) >= 0) {
        console.log("secondes change")
        setSeconds(seconds => newSeconds);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [seconds]);


  function zeroPad(n) {
      return (n < 10) ? '0' + n : '' + n;
  }

  function clockDisplayOfSeconds(secondsCount, hours=false) {
    console.log("display: ", secondsCount)
    const hourString = hours ? '00:' : ''
    return hourString + zeroPad(Math.floor(secondsCount/60)) + ':' + zeroPad(secondsCount%60)
  }

  return (
    <div className={`text-center ${(props.countStart - seconds) == 0 && 'text-red-500'}`}>
      {clockDisplayOfSeconds(props.countStart - seconds)}
    </div>
  )
}

export default Countdown;
