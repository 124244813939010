import React, { useState, useEffect } from 'react';
import * as moment from 'moment'

import {
  Switch,
  Route,
  useParams,
  withRouter
} from "react-router-dom";

import { withFirebase } from './Firebase';

import Setup from './Setup';
import Presentation from './Presentation';
import TherapistControl from './TherapistControl';
import StartPresentation from './StartPresentation';
import PresentationActivity from './PresentationActivity';

import './App.css';

function App(props) {
  const [start, setStart] = useState(0);
  const [pairingIndex, setPairingIndex] = useState(0);
  const [trialStatus, setTrialStatus] = useState("blank"); // 'blank', 'select', or 'playing'
  const [results, setResults] = useState({999999: {empty: true} });
  const [sessionKey, setSessionKey] = useState('');

  const [doubleTrialsConfig, setDoubleTrialsConfig] = useState(false);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    return () => {
        // runs when component un-mounts
    }
  }, [])

  const videos = [
    {
      stimuli: 1,
      title: "Advanced Aircraft",
      youtube: "ZhenuH22LxY",
    },
    {
      stimuli: 2,
      title: "Interstellar Theme",
      youtube: "1ISgr-2qdK0",
    },
    {
      stimuli: 3,
      title: "Oddly Satisfying",
      youtube: "dsYxRD1H1co",
    },
    {
      stimuli: 4,
      title: "Synchronized Motorcycles",
      youtube: "K6iqL2VxmyU",
    },
    {
      stimuli: 5,
      title: "Sleepy Train",
      youtube: "DTba0bNEV14",
    },
  ]

  const defaultPairings = [
    [1,2],
    [5,4],
    [3,1],
    [2,4],
    [4,5],
    [3,2],
    [1,5],
    [3,4],
    [5,1],
    [1,4],
    [2,3],
    [3,5],
    [4,2],
    [5,2],
    [4,3],
    [2,5],
    [1,3],
    [4,1],
    [2,1],
    [5,3],
  ]

  const reversedPairings = () => {
    return defaultPairings.map( (pairing) => [pairing[1], pairing[0]] )
  }

  const pairings = () => {
    return doubleTrialsConfig ? defaultPairings.concat(reversedPairings()) : defaultPairings
  }

  const currentPairing = () => {
    let pairing = pairings()[pairingIndex]

    return([
      videos[pairing[0] - 1],
      videos[pairing[1] - 1]
    ])
  }

  const currentPairingResults = () => {
    return(results[pairingIndex])
  }

  const updatePresentationFirebase = (updates) => {

    let newConfig = {...{
      start: (start == 0) ? 0 : start.getTime(),
      pairingIndex: pairingIndex,
      results: results,
      doubleTrialsConfig: doubleTrialsConfig,
      trialStatus: trialStatus
    }, ...updates};

    console.log("FireB update: ", newConfig)
    props.firebase.database().ref('sessions/' + sessionKey + '/presentation').set(newConfig);
  }


  const navClick = (index) => {
    updatePairingIndex(index, "select")
  }

  const startTrialClick = () => {
    setTrialStatus("select")
    updatePresentationFirebase({trialStatus: "select"})
  }

  const updatePairingIndex = (index, trialStatus) => {
    setPairingIndex(index);
    setTrialStatus(trialStatus)
    updatePresentationFirebase({pairingIndex: index, trialStatus: trialStatus})
  }

  const startSession = () => {
    let startDate = new Date()
    setStart(startDate)
    updatePresentationFirebase({start: startDate.getTime()})

    if(sessionKey){props.history.push("/therapist/" + sessionKey)}
  }

  const engageStimuli = (stimuli) => {
    let timestamp = moment(new Date()).subtract(start).format('mm:ss')
    let newResults = Object.assign({}, results)

    newResults[pairingIndex] = {stimuli: stimuli, sessionTime: timestamp}

    setResults(newResults);
    setTrialStatus("playing")
    updatePresentationFirebase({results: newResults, trialStatus: "playing"})
  }

  const completeTrialClick = () => {
    let newIndex = pairingIndex + 1

    if(newIndex < pairings().length){
      updatePairingIndex(newIndex, "blank")
    }
  }

  const doubleTrialsClick = (value) => {
    setDoubleTrialsConfig(!doubleTrialsConfig);
    updatePresentationFirebase({doubleTrialsConfig: !doubleTrialsConfig})
  }

  return (
    <div className="h-full">
      <Switch>
        <Route exact path="/">
          <Setup
            videos={videos}
            startSession={startSession}
            setSessionKey={setSessionKey}
            sessionKey={sessionKey}
            doubleTrialsConfig={doubleTrialsConfig}
            doubleTrialsClick={doubleTrialsClick}
          />
        </Route>

        <Route path="/therapist/:sessionId">
          <StartPresentation
            start={start}
            startSession={startSession}
            sessionKey={sessionKey}
            setSessionKey={setSessionKey}
          />

          <Presentation
            viewer="therapist"
            start={start}
            currentPairing={currentPairing}
            currentPairingResults={currentPairingResults}
            engageStimuli={engageStimuli}
            pairingIndex={pairingIndex}
            sessionKey={sessionKey}
            setPairingIndex={setPairingIndex}
            trialStatus={trialStatus}
            startTrialClick={startTrialClick}
            completeTrialClick={completeTrialClick}
          />
          <TherapistControl
            pairings={pairings()}
            videos={videos}
            pairingIndex={pairingIndex}
            navClick={navClick}
            results={results}
          />
        </Route>

        <Route path="/:sessionId">
          <PresentationActivity
            sessionKey={sessionKey}
            setStart={setStart}
            setPairingIndex={setPairingIndex}
            setResults={setResults}
            setDoubleTrialsConfig={setDoubleTrialsConfig}
            setTrialStatus={setTrialStatus}
          />

          <Presentation
            viewer="patient"
            start={start}
            currentPairing={currentPairing}
            currentPairingResults={currentPairingResults}
            engageStimuli={engageStimuli}
            pairingIndex={pairingIndex}
            setPairingIndex={setPairingIndex}
            setStart={setStart}
            trialStatus={trialStatus}
          />
        </Route>

      </Switch>
    </div>
  );
}

export default withFirebase(withRouter(App));
