import React, { useState, useEffect } from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from "react-router-dom";

function ClientUrlReminder(props) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(true);
    setTimeout(() => { setActive(false); }, 100);
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    else if (document.selection) {document.selection.empty();}
  };

  return (
    <div className="absolute top-0 left-0">
      <div className="relative max-w-7xl">
        <dl className="flex rounded-br-lg bg-gray-200 shadow-lg p-1 select-text">
          <span className="text-xs p-1 font-medium text-gray-500">
            Client URL:
          </span>
          <CopyToClipboard text={`http://paired-stimulus.abasession.org/#/${props.sessionKey}`} onCopy={toggleClass}>
            <span className={`ml-1 text-xs p-1 text-gray-700 cursor-pointer ${isActive ? 'hover:bg-blue-300': 'hover:underline'} transition ease-in-out duration-200`} >
              http://paired-stimulus.abasession.org/#/{props.sessionKey}
            </span>
          </CopyToClipboard>
          <Link to={`/${props.sessionKey}`} target="_blank" className='flex justify-center'>
            <svg className="text-gray-600 w-4 ml-1 -mt-1 cursor-pointer hover:text-gray-700" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z" clipRule="evenodd"></path>
              <path fillRule="evenodd" d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clipRule="evenodd"></path>
            </svg>
          </Link>
        </dl>
      </div>
    </div>
  );
}

export default ClientUrlReminder;
