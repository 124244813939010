import React, { useState, useEffect } from 'react';

function PreventClicks(props) {
  const noClicks = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className="w-screen h-screen absolute" onClick={(e) => noClicks(e)} >
    </div>
  );
}

export default PreventClicks;
