import React, { useState, useEffect } from 'react';

function Video(props) {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    // if viewer == 'patient'
    // firebase listener here to play videos for patients
    //   check video key
    //   setPlaying if it's playing


    return () => {
        // console.log("UNmouting video: ", props.video.stimuli)
    }
  }, [])

  const choseVideo = () => {
    if(props.viewer == 'therapist'){
      setPlaying(true)
      props.engageStimuli(props.video.stimuli)
    }
  }

  const selected = props.pairingResults && props.pairingResults.stimuli == props.video.stimuli

  const makeSelection = !props.pairingResults

  const notSelected = props.pairingResults && props.pairingResults.stimuli != props.video.stimuli

  return (
    <div className={`${makeSelection && 'card materials flex-grow'} ${selected && 'h-full w-full'}`}>
      {selected &&
        <div className="h-full w-full bg-black">
          <iframe title="facet-youtube" className="w-full h-full" src={"https://www.youtube.com/embed/" + props.video.youtube + "?autoplay=1"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      }
      {makeSelection &&
        <img
          className={`${props.viewer == 'therapist' && 'cursor-pointer'}`}
          src={"https://img.youtube.com/vi/" + props.video.youtube + "/hqdefault.jpg"}
          onClick={() => choseVideo()}
        />
      }

      {false &&
        <div className="w-0 h-0">
          // attempt preload
          <iframe title="facet-youtube" width="0" height="0" src={"https://www.youtube.com/embed/" + props.video.youtube} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      }
    </div>
  );
}

export default Video;
