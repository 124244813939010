import React from 'react';
import * as moment from 'moment'

function NavItem(props) {
  const selected = (video) => {
    return(props.result && props.result.stimuli == video.stimuli)
  }

  return (
    <li
      className={`
        flex flex-row justify-between border-b-2 text-gray-600
        ${props.completed ? 'border-gray-300' : 'border-blue-200 hover:border-blue-400'}
        ${props.active && 'border-indigo-500 hover:border-indigo-500'}`
      }
      onClick={() => props.navClick && props.navClick(props.pairingIndex)}
    >

      {props.first &&
        <div className="pl-2 pt-1 w-1/2 text-left flex flex-row justify-between" >
          <span className={`
            ${!props.result && props.active && "text-indigo-600"}
            ${props.result && props.active && "text-gray-600"}
            ${props.active && selected(props.second) && "text-gray-600"}
            ${!props.active && selected(props.first) && "text-green-600"}
            ${props.active && selected(props.first) && "text-green-600 font-bold"}
          `} >
            {props.first.stimuli}: {props.first.title}
          </span>
          <span className="text-gray-600 text-xs mr-4">
            { props.result && selected(props.first) &&
              moment(props.result.sessionTime, 'mm:ss').format(' mm:ss ')
            }
          </span>
        </div>
      }

      {props.second &&
        <div className="pr-2 pt-1 w-1/2 text-right flex flex-row justify-between" >
          <span className="text-gray-600 text-xs ml-4">
            { props.result && selected(props.second) &&
              moment(props.result.sessionTime, 'mm:ss').format(' mm:ss ')
            }
          </span>
          <span className={`
            ${!props.result && props.active && "text-indigo-600"}
            ${props.result && props.active && "text-gray-600"}
            ${props.active && selected(props.first) && "text-gray-600"}
            ${!props.active && selected(props.second) && "text-green-600"}
            ${props.active && selected(props.second) && "text-green-600 font-bold"}
          `} >
            {props.second.stimuli}: {props.second.title}
          </span>
        </div>
      }
    </li>
  );
}

export default NavItem;
