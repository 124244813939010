import React from 'react';

import NavItem from './NavItem';

function Nav(props) {
  return (
    <ul className="shadow-xl cursor-pointer p-1 list-reset flex flex-col text-left space-y-1 max-h-full overflow-y-auto flex-grow">
      {props.pairings && props.pairings.map( (pairing, pairingIndex) => {
        let firstVideo = props.videos[pairing[0] - 1]
        let secondVideo = props.videos[pairing[1] - 1]

        return (
          <NavItem
            key={pairingIndex}
            pairingIndex={pairingIndex}
            first={firstVideo}
            second={secondVideo}
            result={props.results[pairingIndex]}
            active={props.pairingIndex == pairingIndex}
            completed={props.pairingIndex > pairingIndex}
            navClick={props.navClick}
          />
        )
      })}
      <li className='p-1'>
      </li>
    </ul>
  );
}

export default Nav;
