import React, { useEffect } from 'react';

import { withFirebase } from './Firebase';
import {
  withRouter, useParams
} from "react-router-dom";

function PresentationActivity(props) {

  useEffect(() => {
    let sessionId = ((props.match || {}).params || {}).sessionId
    console.log('sessions/' + sessionId + '/presentation')

    props.firebase.
      database().
      ref('sessions/' + sessionId + '/presentation').
      on('value', function(snapshot) {
        const presentation = snapshot.val();
        console.log("fb: ", presentation)

        if(presentation){
          props.setDoubleTrialsConfig(presentation.doubleTrialsConfig)
          props.setPairingIndex(presentation.pairingIndex)
          props.setResults(presentation.results)
          props.setStart(presentation.start)
          props.setTrialStatus(presentation.trialStatus)
        }
      });

    return () => {
        // runs when component un-mounts
    }
  }, [])


  return (
    <div></div>
  );
}

export default withFirebase(withRouter(PresentationActivity));
