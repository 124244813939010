import React, { useState, useEffect } from 'react';

import { withFirebase } from './Firebase';

import Video from './Video';
import PreventClicks from './PreventClicks';
import ClientUrlReminder from './ClientUrlReminder';
import TrialNav from './TrialNav';

import {
  withRouter, useParams
} from "react-router-dom";

function Presentation(props) {

  useEffect(() => {
    onListenForMessages()

    return () => {
        // runs when component un-mounts
    }
  }, [])


  const onListenForMessages = () => {
    if(props.viewer == 'therapist'){
      props.firebase.
        database().
        ref('sessions/' + props.sessionKey + '/click').
        on('value', function(snapshot) {
          // updatePresentation(snapshot);
        });

      props.firebase.
        database().
        ref('sessions/' + props.sessionKey + '/mouse').
        on('value', function(snapshot) {
          // updatePresentation(snapshot);
        });
    }
  }

  let currentPairing = props.currentPairing() || []

  return (
    <div id="center-wrapper" className={`${props.viewer}-presentation flex flex-row`}>
      {!!props.start &&
        <div className="h-full w-full relative">
          <div id="presentation" className={`h-full main-content flex-1 overflow-y-auto select-none ${(props.viewer == 'therapist') && 'border-2 border-black border-b-0'}`}>
            <div className="h-full flex ">

              <div className="flex flex-col w-full">
                <div className={`cards flex flex-grow items-center mx-auto w-full`}>

                  { props.viewer == 'therapist' &&
                    <ClientUrlReminder sessionKey={props.sessionKey} />
                  }

                  {(props.trialStatus != 'blank') && currentPairing.map( (video) => {
                    return (
                      <Video
                        key={"v-" + props.pairingIndex + "-" + video.stimuli}
                        viewer={props.viewer}
                        video={video}
                        engageStimuli={props.engageStimuli}
                        pairingResults={props.currentPairingResults()}
                      />
                    )
                  })}

                  { props.viewer == 'patient' &&
                    <PreventClicks />
                  }
                </div>

              </div>
            </div>
          </div>

          { props.viewer == 'therapist' &&
            <TrialNav
              trialStatus={props.trialStatus}
              currentPairing={currentPairing}
              startTrialClick={props.startTrialClick}
              engageStimuli={props.engageStimuli}
              completeTrialClick={props.completeTrialClick}
            />
          }
        </div>
      }
      { !! !props.start &&
        <div className='flex flex-col h-full w-full align-center'>
          <h2 className="mt-6 sm:mt-24 text-center text-3xl leading-9 font-extrabold text-gray-900">
            ABA Session
          </h2>
          <p className="mt-2 text-gray-600 italic text-center">
            Waiting to start...
          </p>
        </div>
      }
    </div>
  );
}

export default withFirebase(withRouter(Presentation));
