import React, { useState, useEffect } from 'react';

import Nav from './Nav';

function TherapistControl(props) {
  return (
    <div id="controls-bottom" className="bg-gray-300 flex justify-between">
      <div className="w-full mx-4 sm:mx-32 lg:mx-64">
        <div className="text-left p-2 h-full">
          <div className="h-full bg-gray-100 flex flex-row">
            <Nav
              pairings={props.pairings}
              videos={props.videos}
              pairingIndex={props.pairingIndex}
              navClick={props.navClick}
              results={props.results}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TherapistControl;
