import React, { useState } from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import {Link} from "react-router-dom";

function Setup(props) {
  const selected = (video) => {
    return(props.result && props.result.stimuli == video.stimuli)
  }

  let trialIdInput = React.createRef();

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(true);
    setTimeout(() => { setActive(false); }, 100);
  };

  return (
    <div className="h-full flex justify-center bg-gray-50 px-4 overflow-y-auto">
      <div className="max-w-xl w-full">
        <div>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Paired Stimulus Trial
          </h2>
        </div>

        { props.sessionKey === '' &&
          <div className="mt-16">
            <div className="flex items-center border-b border-teal-500 pb-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Trial id for this client"
                aria-label="trial ID"
                ref={trialIdInput}
              />
              <button
                className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="button"
                onClick={() => props.setSessionKey(trialIdInput.current.value)}
              >
                Create Trial
              </button>
            </div>
            <p className="mt-2 text-gray-600 text-xs italic">Use a unique sequence of A-Z, a-z, and 0-9</p>
          </div>
        }

        { props.sessionKey !== '' &&
          <div className="mt-16 flex flex-col justify-center">
            <div className="flex flex-col">
              <h3 className="text-xl font-extrabold text-gray-900">
                Client URL:
              </h3>
              <span className="mt-2 flex text-red-700 justify-center">
                <CopyToClipboard text={`http://paired-stimulus.abasession.org/#/${props.sessionKey}`} onCopy={toggleClass}>
                  <span className={`p-3 cursor-pointer ${isActive ? 'hover:bg-white': 'hover:bg-gray-300'} transition ease-in-out duration-100`} >
                    http://paired-stimulus.abasession.org/#/{props.sessionKey}
                  </span>
                </CopyToClipboard>

                <Link to={`/${props.sessionKey}`} target="_blank" className='flex justify-center'>
                  <svg className="text-gray-600 w-5 ml-2 cursor-pointer hover:text-gray-700" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clipRule="evenodd"></path>
                  </svg>
                </Link>
              </span>
              <span className=" text-center text-gray-600 text-xs italic">
                Your client loads this url on their computer
              </span>
            </div>
            <div className="flex flex-col items-center mt-8">
              <div className="flex items-start">
                <div className="h-5 flex items-center">
                  <input
                    id="comments"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={props.doubleTrialsConfig}
                    onChange={props.doubleTrialsClick}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Duplicate trials on opposite sides
                  </label>
                  <p className="text-gray-500">
                    Use this option to run each trial twice with stimuli on the opposite side
                  </p>
                </div>
              </div>

              <div className="mt-2 w-1/2 flex justify-center">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-4 border border-transparent text-xl leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                    onClick={props.startSession}
                  >
                    <span>Start Trial</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        }


        <div className="mt-16 mb-8">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
            Trial Stimuli
          </h2>

          <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-3">
            {(props.videos || []).map( (video) => {
              return (
                <li key={video.stimuli} className="col-span-1 flex shadow-sm rounded-md mx-auto sm:mx-unset">
                  <a href={"https://www.youtube.com/watch?v=" + video.youtube} target="_blank">
                    <img src={"https://img.youtube.com/vi/" + video.youtube + "/hqdefault.jpg"} />
                  </a>
                </li>
              )
            })}
          </ul>

        </div>
      </div>
    </div>
  );
}

export default Setup;
