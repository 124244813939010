import React, { useEffect } from 'react';

import {
  withRouter, useParams
} from "react-router-dom";

function StartPresentation(props) {

  useEffect(() => {
    if(props.start == 0 || props.start == undefined){
      props.startSession()
    }

    let sessionId = ((props.match || {}).params || {}).sessionId
    if(props.sessionKey == ''){
      props.setSessionKey(sessionId)
    }

    return () => {
        // runs when component un-mounts
    }
  }, [])


  return (
    <div></div>
  );
}

export default withRouter(StartPresentation);
