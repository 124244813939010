import React, { useState, useEffect } from 'react';

import Countdown from './Countdown';

function TrialNav(props) {
  return (
    <div className="absolute bottom-0 left-0 bg-gray-300 border-black border-0 border-t-2 w-full h-24 pt-4">
      {props.trialStatus == 'blank' &&
        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-center mr-6">
            <Countdown countStart={15} />
            <div className="text-xs text-gray-600">
              between trials
            </div>
          </div>
          <button
            className="inline-flex items-center justify-center px-4 py-4 border border-transparent text-xl leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            type="button"
            onClick={() => props.startTrialClick()}
          >
            Start Trial
          </button>
        </div>
      }

      {props.trialStatus == 'select' &&
        <div className="flex justify-center">
          {props.currentPairing[0] &&
            <button
              className="inline-flex items-center justify-center px-4 py-4 border border-transparent text-xl leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
              type="button"
              onClick={() => props.engageStimuli(props.currentPairing[0].stimuli)}
            >
              Select Video
            </button>
          }

          <div className="w-12 sm:w-32 h-2">
          </div>

          <button
            className="inline-flex items-center justify-center px-4 py-4 border border-transparent text-xl leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            type="button"
            onClick={() => props.engageStimuli(props.currentPairing[1].stimuli)}
          >
            Select Video
          </button>
        </div>
      }

      {props.trialStatus == 'playing' &&
        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-center mr-6">
            <Countdown countStart={30} />
            <div className="text-xs text-gray-600">
              play time
            </div>
          </div>
          <button
            className="inline-flex items-center justify-center px-4 py-4 border border-transparent text-xl leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            type="button"
            onClick={() => props.completeTrialClick()}
          >
            Next Trial
          </button>
        </div>
      }
    </div>
  );
}

export default TrialNav;
